@import "node_modules/react-modal-video/scss/modal-video.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.top-nav {
  .show-less {
    display: none;
  }
  & > a {
    padding: 24px 16px;
    height: 72px;
    &:before {
      content: "";
      position: absolute;
      z-index: -2;
      width: 0;
      right: 0;
      bottom: 0;
      background: #172b50;
      height: 8px;
      transition-property: width;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }
  }
  ul {
    background: #fff;
    z-index: 99;
    display: none;
    li {
      &:hover {
        background: #ececec;
      }
    }
  }
  &:hover {
    .show-less {
      display: block;
    }
    .show-more {
      display: none;
    }
    & > a:before {
      left: 0;
      right: auto;
      width: 100%;
    }
    ul {
      display: block;
    }
  }
}

.trusted-company {
  width: 272px;
  height: 135px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #c4c4c4;
    transform: matrix(0.89, 0, 0.6, 1, 0, 0);
    width: 230px;
    height: 135px;
  }
}

.feature-card {
  position: relative;
  &__top {
    margin-top: 0;
    transition: margin 0.2s ease-in-out;
  }
  &:hover {
    filter: drop-shadow(0px 4px 16px rgba(60, 60, 60, 0.4));
    .feature-card__top {
      margin-top: -378px;
    }
  }
}

.rpmmy-card {
  &__hover {
    margin-top: 0;
    transition: margin 0.2s ease-in-out;
  }
  &:hover {
    .rpmmy-card__hover {
      margin-top: -311px;
    }
  }
}

.support-box {
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #0b1232;
    border-left: 20px solid #0076b6;
    transform: matrix(0.89, 0, 0.6, 1, 0, 0);
    z-index: 0;
  }
}

.MuiMobileStepper-dot {
  display: none;
}
.modal-video {
  background: rgba(0, 0, 0, 0.85);
}

.article-content {
  font-family: "Roboto";
  font-size: 1.125rem;
  color: #0c1932;
  p {
    margin-bottom: 20px;
    line-height: 1.5;
  }
  a {
    color: #0c1932;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 6px 0 10px;
  }
  ol,
  ul {
    margin: 0 0 20px 30px;
    li {
      padding: 8px 0 0;
    }
  }
  blockquote {
    margin: 30px auto;
    font-family: Open Sans;
    font-style: italic;
    color: #555;
    padding: 1.2em 30px 1.2em 50px;
    border-left: 8px solid #173162;
    line-height: 1.6;
    position: relative;
    background: #ededed;
  }
}

.paginator {
  list-style-type: none;
  display: block;
  li {
    display: inline-block;
    overflow: hidden;
    margin: 0 6px;
    font-family: "Roboto";
    &.page {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &.active {
        border: 1px solid #797986;
        cursor: default;
      }
    }
    &.previous,
    &.next {
      a {
        display: block;
        width: 40px;
        height: 40px;
        background: #f6f6f6;
        border-radius: 20px;
        line-height: 40px;
        text-align: center;
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.83019 5.2897L2.59019 1.0497C2.49722 0.955976 2.38662 0.881582 2.26476 0.830813C2.1429 0.780044 2.0122 0.753906 1.88019 0.753906C1.74818 0.753906 1.61747 0.780044 1.49561 0.830813C1.37375 0.881582 1.26315 0.955976 1.17019 1.0497C0.983936 1.23707 0.879395 1.49052 0.879395 1.7547C0.879395 2.01889 0.983936 2.27234 1.17019 2.4597L4.71019 5.9997L1.17019 9.5397C0.983936 9.72707 0.879395 9.98052 0.879395 10.2447C0.879395 10.5089 0.983936 10.7623 1.17019 10.9497C1.26363 11.0424 1.37444 11.1157 1.49628 11.1655C1.61812 11.2152 1.74858 11.2405 1.88019 11.2397C2.01179 11.2405 2.14226 11.2152 2.26409 11.1655C2.38593 11.1157 2.49675 11.0424 2.59019 10.9497L6.83019 6.7097C6.92392 6.61674 6.99831 6.50614 7.04908 6.38428C7.09985 6.26242 7.12599 6.13172 7.12599 5.9997C7.12599 5.86769 7.09985 5.73699 7.04908 5.61513C6.99831 5.49327 6.92392 5.38267 6.83019 5.2897Z' fill='%23111827'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        cursor: pointer;
      }
      &.disabled {
        a {
          opacity: 0.5;
          cursor: default;
        }
      }
    }
    &.previous {
      a {
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.17519 6.70392L5.41519 10.9439C5.50816 11.0377 5.61876 11.112 5.74062 11.1628C5.86247 11.2136 5.99318 11.2397 6.12519 11.2397C6.2572 11.2397 6.38791 11.2136 6.50977 11.1628C6.63163 11.112 6.74223 11.0377 6.83519 10.9439C7.02144 10.7566 7.12598 10.5031 7.12598 10.2389C7.12598 9.97474 7.02144 9.72129 6.83519 9.53392L3.29519 5.99392L6.83519 2.45392C7.02144 2.26656 7.12599 2.01311 7.12599 1.74892C7.12599 1.48474 7.02144 1.23129 6.83519 1.04392C6.74175 0.951241 6.63094 0.877917 6.5091 0.828152C6.38726 0.778388 6.2568 0.753163 6.12519 0.753924C5.99359 0.753163 5.86312 0.778387 5.74129 0.828152C5.61945 0.877917 5.50863 0.951241 5.41519 1.04392L1.17519 5.28392C1.08146 5.37689 1.00707 5.48749 0.956302 5.60935C0.905533 5.73121 0.879395 5.86191 0.879395 5.99392C0.879395 6.12593 0.905533 6.25664 0.956302 6.3785C1.00707 6.50036 1.08146 6.61096 1.17519 6.70392Z' fill='%23111827'/%3E%3C/svg%3E");
      }
    }
  }
}
